<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogAnaliseCredito"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Solicitar análise de crédito
				</v-card-title>

				<v-card-text 
					v-if="dadosAnaliseCredito != null && dadosAnaliseCredito != undefined"
					style="margin-top: 20px; text-align: center;"
				>
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<span>Esse cliente <strong>{{ dadosAnaliseCredito.nomeCliente }}</strong> tem um limite de crédito no valor de: <strong>{{ dadosAnaliseCredito.limiteCredito }} </strong> e será necessário solicitar uma análise prévia.</span>
							<br />
							<span>Segue abaixo os documentos necessários para envio:</span>
							<br />
							<br />
							<p>
								<strong>{{ dadosAnaliseCredito.descricao }}</strong>
							</p>
							<label>{{ dadosAnaliseCredito.descricaoAnaliseDias }}</label>
						</v-col>
					</v-row>					

                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-file-input
                                v-model="documentosListFiles" 
                                accept=".*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Clique aqui para adicionar todos os documentos"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="addFilesToList(documentosListFiles, documentosFiles)"
                            ></v-file-input>
                        </v-col>
                    </v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus botaoDispensar"
							outlined
							@click="dispensar()"
						>
							Fechar
						</v-btn>

						<v-btn
							class="mx-2 btn-plus"
							outlined
							@click="enviarSolicitacao()"
						>
							Enviar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodAcao1ToCall="dialog.methodAcao1"
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :button1Text="dialog.button1Text"
            :button2Text="dialog.button2Text"
            :dismissDialog="dialog.dismissDialog"
            :params="dialog.params"
        />
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default {

        mixins: [Helpers],

        components: {
            ActionDialog
        },

        props: {

			showDialogAnaliseCredito: {
				default: false
			},

			dadosAnaliseCredito: {
				type: Object,
				default: null
			},
		},
		
        data: () => ({
            documentosListFiles: [],
            documentosFiles: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                button1Text: "Cancelar",
                button2Text: "Confirmar",
                methodAcao1: ( () => {} ),
                methodConfirm: ( () => {} ),
                dismissDialog: true,
                params: null
            },
		}),

        methods: {

            dispensar() {
				this.$emit('update:showDialogAnaliseCredito', false);
            },			

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let item = {
                                id: 0,
                                arquivo: itemFile,
                                nomeArquivo: itemFile.name,
                                excluido: false,
                            }

                            files.push(item);
						}	
					});
                }
            },

			enviarSolicitacao() {

				if (this.documentosFiles == null || this.documentosFiles == undefined || this.documentosFiles.length <= 0) {
					this.showToast("error", "Error!", `Para enviar o e-mail é necessário anexar pelo menos 1 arquivo!`);
				}
				else {

					this.dialog = {
						show: true,
						headerText: 'Confirmação',
						bodyText: 'Você irá enviar um e-mail para contasareceber@sptech.com para solicitar a análise de crédito desse cliente, confirma o envio?',
						methodAcao1: ( () => {} ),
						methodConfirm: this.enviarEmail,
						params: 0
					};
				}				
			},

			async enviarEmail() {

                if (this.dadosAnaliseCredito != null && this.dadosAnaliseCredito != undefined) {

					this.showLoading();

                    let analiseCreditoRequest = new FormData();

                    analiseCreditoRequest.append("dadosAnaliseCreditoRequest", JSON.stringify(this.dadosAnaliseCredito));

					this.documentosFiles.forEach( itemArquivo => {
						analiseCreditoRequest.append(`listDocumentos`, itemArquivo.arquivo, itemArquivo.nomeArquivo);
					})

                    const result = await this.$store.dispatch("orcamentoModule/EnviarEmailAnaliseCredito", analiseCreditoRequest);

                    if (result.success == true) {
						this.showToast("success", "Sucesso!", `E-mail enviado com sucesso!`);
						this.dispensar();
					}
					else {
						this.showToast("error", "Error!", `Tivemos um problema ao enviar o e-mail, por favor, tente novamente!`);
					}
					
					this.hideLoading();
                }
                else {
                    return {
                        success: true
                    };
                }
			}
        }
    };
</script>
