<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogCliente"
			transition="dialog-top-transition"
			persistent
			width="1000"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Novo Cliente
				</v-card-title>

				<v-card-text>
					<v-form
						ref="form"
						v-model="validForm"
						lazy-validation
					>
						<v-row>
							<v-col 
								cols="12"
								lg="3"
								md="6"
								sm="12"
							>                    
								<v-radio-group 
									row 
									v-model="clienteRequest.pessoa"
									style="width: 100%"
								>
									<v-radio label="Física"  value="F" color="var(--color__main)"></v-radio>
									<v-radio label="Jurídica" value="J" color="var(--color__main)"></v-radio>
								</v-radio-group>
							</v-col>
							<v-col
								cols="12"
								lg="5"
								md="5"
								sm="12"
							>
								<label>Tipo</label>
								<v-combobox
									v-model="clienteRequest.tipoSelected"
									:rules=[validations.required]
									:items="listTipo"
									item-text="descricao"
									item-value="id"
									outlined
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Tipo Cliente</label>
								<v-combobox
									v-model="clienteRequest.tipoClienteSelected"
									:rules=[validations.required]
									:items="listTipoClienteCadastro"
									item-text="descricao"
									item-value="id"
									outlined
								></v-combobox>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col 
								v-if="isPF"
								cols="12"
								lg="4"
								md="6"
								sm="12"
							>
								<label>CPF</label>
								<v-text-field
									v-model="clienteRequest.cpfCnpj"
									:rules=[validations.required,validations.validCpf]
									v-mask="'###.###.###-##'"
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col 
								v-else
								cols="12"
								lg="4"
								md="6"
								sm="12"
							>
								<label>CNPJ</label>
								<v-text-field
									v-model="clienteRequest.cpfCnpj" 
									:rules=[validations.required,validations.validCnpj]
									v-mask="'##.###.###/####-##'"
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col 
								cols="12"
								:lg="isPF ? 12 : 4"
								:md="isPF ? 12 : 6"
								sm="12"
							>
								<label v-if="isPF">Nome</label>
								<label v-else>Razão Social</label>
								<v-text-field
									v-model="clienteRequest.nome"
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col 
								v-if="isPJ"
								cols="12"
								lg="4"
								md="6"
								sm="12"
							>
								<label>Nome Fantasia</label>
								<v-text-field
									v-model="clienteRequest.fantasia"
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col 
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Segmento</label>
								<v-combobox
									v-model="clienteRequest.segmentoSelected"
									:rules=[validations.required]
									:items="listSegmentos"
									item-text="nameProcessed"
									item-value="idProcessed"
									outlined
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Sub Segmento</label>
								<v-combobox
									v-model="clienteRequest.subSegmentoSelected"
									:rules=[validations.required]
									:items="listSubSegmentos"
									item-text="nameProcessed"
									item-value="idProcessed"
									outlined
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Vendedor</label>
								<v-combobox
									v-model="clienteRequest.vendedorSelected"
									:rules=[validations.required]
									:items="listVendedores"
									item-text="nameProcessed"
									item-value="idProcessed"
									outlined
								></v-combobox>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>CEP</label>
								<v-text-field
									v-model="clienteRequest.cep"
									:rules=[validations.required]
									v-mask="'#####-###'"
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								lg="8"
								md="8"
								sm="12"
							>
								<label>Endereço</label>
								<v-text-field
									v-model="clienteRequest.endereco" 
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Bairro</label>
								<v-text-field
									v-model="clienteRequest.bairro"
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col 
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Estado</label>
								<v-combobox
									v-model="clienteRequest.estadoSelected"
									:rules=[validations.required]
									:items="listEstados"
									item-text="codigo"
									item-value="codigo"
									outlined
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Município</label>
								<v-combobox
									v-model="clienteRequest.municipioSelected"
									:rules=[validations.required]
									:items="listMunicipios"
									item-text="codigoDescricao"
									item-value="codigo"
									outlined
								></v-combobox>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col 
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Telefone</label>
								<v-text-field
									v-model="clienteRequest.telefone"
									v-mask="'(##) #########'"
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								lg="8"
								md="8"
								sm="12"
							>
								<label>Contato</label>
								<v-text-field
									v-model="clienteRequest.contato"
									:rules=[validations.required]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
						</v-row>
							
						<v-row>
							<v-col
								cols="12"
								lg="4"
								md="4"
								sm="12"
							>
								<label>Contribuinte?</label>
								<v-combobox
									v-model="clienteRequest.contribuinteSelected"
									:rules=[validations.required]
									:items="listSimNao"
									item-text="descricao"
									item-value="id"
									outlined
								></v-combobox>
							</v-col>
							<v-col
								cols="12"
								lg="8"
								md="8"
								sm="12"
							>
								<label>E-Mail</label>
								<v-text-field
									v-model="clienteRequest.email"
									:rules=[validations.required,validations.validEmail]
									single-line
									outlined
								>
								</v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus dialogButtonCancel"
							outlined
							@click="cancelar()"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2 btn-plus"
							outlined
							@click="salvar()"
						>
							Salvar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodAcao1ToCall="dialog.methodAcao1"
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :button1Text="dialog.button1Text"
            :button2Text="dialog.button2Text"
            :dismissDialog="dialog.dismissDialog"
            :params="dialog.params"
        />
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { required, validCnpj, validCpf, validEmail } from "@/utilities/Rules";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { 
        LIST_TIPO, 
        LIST_TIPO_CLIENTE_CADASTRO
    } from "@/utilities/Enums";

    export default {

        mixins: [Helpers],

        components: {
            ActionDialog
        },

        props: {

			idAux: {
				type: Number,
				default: 0
			},

			showDialogCliente: {
				default: false
			},
		},
		
        data: () => ({

            validForm: true,

			clienteRequest: {
				pessoa: "J",
				nome: '',
				fantasia: '',
				segmentoSelected: '',
				subSegmentoSelected: '',
				vendedorSelected: '',
				tipoSelected: null,
				tipoClienteSelected: null,
				cpfCnpj: '',
				endereco: '',
				cep: '',
				bairro: '',
				estadoSelected: '',
				municipioSelected: '',
				telefone: '',
				contato: '',
				email: '',
				contribuinteSelected: null,
			},

			newClienteRequest: null,

			listTipo: LIST_TIPO,
			listTipoClienteCadastro: LIST_TIPO_CLIENTE_CADASTRO,
            listEstados: [],
            listSegmentos: [],
            listSubSegmentos: [],
            listVendedores: [],
            listMunicipios: [],
			listSimNao: [
				{ id: "1", descricao : "SIM"},
				{ id: "2", descricao : "NÃO"},
			],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                button1Text: "Cancelar",
                button2Text: "Confirmar",
                methodAcao1: ( () => {} ),
                methodConfirm: ( () => {} ),
                dismissDialog: true,
                params: null
            },
            
            validations: {
                required: required,
                validCnpj: validCnpj,
                validCpf: validCpf,
				validEmail: validEmail
            }
		}),

		watch: {
			"idAux"() {
				this.clearFields();
			},
		},

		computed: {
			isPF() { return this.clienteRequest.pessoa == "F"; },
			isPJ() { return this.clienteRequest.pessoa == "J"; }
		},

        methods: {

			async clearFields() {
				this.clienteRequest = {...this.newClienteRequest};
				this.clienteRequest.pessoa = "J";
                this.listEstados = await this.$store.dispatch("estadoModule/List");
                this.listSegmentos = await this.$store.dispatch("segmentoModule/List");
                this.listSubSegmentos = await this.$store.dispatch("subSegmentoModule/List");
                this.listVendedores = await this.$store.dispatch("vendedorModule/List");
                this.listMunicipios = await this.$store.dispatch("municipioModule/List");
			},

            cancelar() {
				this.$emit('update:showDialogCliente', false)
            },

            async salvar() {

				await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

					this.dialog = {
						show: true,
						headerText: 'Confirmação',
						bodyText: 'Você irá inserir um novo cliente no Totvs. Confirma sua decisão?',
						methodAcao1: ( () => {} ),
						methodConfirm: this.cadastrarCliente,
						params: 0
					};
				}				
            },

			async cadastrarCliente() {
				
				this.showLoading();

				let clienteRequest = {...this.clienteRequest};

				clienteRequest.cnpjCpf = clienteRequest.cpfCnpj;

				if (clienteRequest.tipoSelected != null && clienteRequest.tipoSelected != undefined) {
					clienteRequest.tipo = clienteRequest.tipoSelected.id;
				}

				if (clienteRequest.tipoClienteSelected != null && clienteRequest.tipoClienteSelected != undefined) {
					clienteRequest.tipoCliente = clienteRequest.tipoClienteSelected.id;
				}

				if (clienteRequest.municipioSelected != null && clienteRequest.municipioSelected != undefined) {
					clienteRequest.codigoMunicipio = clienteRequest.municipioSelected.id.toString();
				}

				if (clienteRequest.segmentoSelected != null && clienteRequest.segmentoSelected != undefined) {
					clienteRequest.codigoSeguimento = clienteRequest.segmentoSelected.idProcessed;
				}

				if (clienteRequest.subSegmentoSelected != null && clienteRequest.subSegmentoSelected != undefined) {
					clienteRequest.codigoSubSeguimento = clienteRequest.subSegmentoSelected.idProcessed;
				}

				if (clienteRequest.vendedorSelected != null && clienteRequest.vendedorSelected != undefined) {
					clienteRequest.codigoVendedor = clienteRequest.vendedorSelected.idProcessed;
				}

				if (clienteRequest.contribuinteSelected != null && clienteRequest.contribuinteSelected != undefined) {
					clienteRequest.contribuinte = clienteRequest.contribuinteSelected.id;
				}

				if (clienteRequest.estadoSelected != null && clienteRequest.estadoSelected != undefined) {
					clienteRequest.estado = clienteRequest.estadoSelected.codigo;
				}

				const result = await this.$store.dispatch("empresaModule/CreateUpdateCliente", clienteRequest);

				if (result.success === true) {
					this.showToast("success", "Sucesso!", result.message);
					this.$emit('methodConfirmToCall', clienteRequest.cnpjCpf);
					this.cancelar();
				}
				else {
					this.showToast("error", "Aviso!", result.message);
				}

				this.loading = false;
				this.hideLoading();
			}
        },

		created() {
			this.newClienteRequest = {...this.clienteRequest};
		}
    };
</script>
