<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialog"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					{{ headerTitle }}
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
					<strong v-html="bodyText" style="font-weight: bold;"></strong>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="button1Action()"
						>
							{{ button1Text }}
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="button2Action()"
						>
							{{ button2Text }}
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    export default {

        props: {

			showDialog: {
				default: false
			},

			headerTitle: {
				type: String,
				default: ""
			},

			bodyText: {
				type: String,
				default: ""
			},

			button1Text: {
				type: String,
				default: "Cancelar"
			},

			button2Text: {
				type: String,
				default: "Confirmar"
			},

			dismissDialog: {
				type: Boolean,
				default: true
			},

			params: {
				default: null
			}
		},

        methods: {

            button1Action() {
                this.$emit('methodAcao1ToCall', this.params);
				if (this.dismissDialog == true || this.button1Text == 'Cancelar') {
					this.$emit('update:showDialog', false)
				}
            },

            button2Action() {
                this.$emit('methodConfirmToCall', this.params);
				if (this.dismissDialog == true) {
					this.$emit('update:showDialog', false)
				}
            }
        }
    };
</script>
