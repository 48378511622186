<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogPrazoEntregaEdit"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Novo Prazo de Entrega
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<v-text-field
								v-model="novoPrazoEntrega" 
								suffix=" dias úteis"
								v-mask="'###'"
								single-line
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus dialogButtonCancel"
							outlined
							@click="cancelar()"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2 btn-plus"
							outlined
							@click="confirmar()"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';

    export default {

        mixins: [Helpers],

        props: {

			showDialogPrazoEntregaEdit: {
				default: false
			},

			prazoEntregaOriginal: {
				type: String,
				default: "0"
			},

			prazoEntrega: {
				type: String,
				default: "0"
			},
		},
		
        data: () => ({
			novoPrazoEntrega: 0
		}),

		watch: {
			"prazoEntrega"() {
				this.novoPrazoEntrega = this.prazoEntrega;
			}
		},

        methods: {

            cancelar() {
				this.$emit('update:showDialogPrazoEntregaEdit', false)
            },

            confirmar() {

				if (parseInt(this.novoPrazoEntrega) < parseInt(this.prazoEntregaOriginal)) {
					this.showToast("error", "Aviso!", `Prazo de entrega não pode ser menor que o original [${this.prazoEntregaOriginal}] dias úteis.`);
				}
				else {
					this.$emit('methodConfirmToCall', this.novoPrazoEntrega);
					this.cancelar();
				}                
            }
        }
    };
</script>
